import { http } from "@/plugins/axios";
import { list } from "@/classes/helpers";
import Router from "@/router/index";
import { useGlobalStore } from '@/store/globalStore';
import i18n from '@/plugins/i18n'

class User {
    constructor(data = { forename: "", surname: "", email: "" }) {
        this.id = null;
        this.forename = data.forename;
        this.surname = data.surname;
        this.email = data.email;
    }

    static async list() {
        return http.get("users/");
    }

    static async init(user) {
        return http.patch("users/init/", user);
    }

    static async tasks() {
        return http.get("users/tasks/");
    }
}


class UserTasks {
    static async list(name = null, params = {}) {
        return list("users/tasks/", name, params, false)
    }

    // Update normal task
    static save(task) {
        const company_id = Router.currentRoute.params?.companyId;

        if (company_id == undefined) {
            return;
        }

        const data = { ...task }

        // Flatten data
        let persons = []
        let departments = []

        // Check if keys in task
        if (Object.hasOwn(data, 'persons')) {
            data.persons.forEach((item) => {
                persons.push(item.id)
            })

            data.persons = persons;
        }

        if (Object.hasOwn(data, 'departments')) {
            data.departments.forEach((item) => {
                departments.push(item.id)
            })

            data.departments = departments;
        }

        if (Object.hasOwn(data, 'status')) {
            data.status = data.status != null ? data.status.id : null;
        }

        if (data.id !== undefined) {
            // Update
            return http.patch("companies/" + company_id + "/tasks/" + data.id + "/", data);
        } else {
            // Create
            return http.post("companies/" + company_id + "/tasks/", data);
        }
    }

    // Remove normal task
    static remove(item) {
        const company_id = Router.currentRoute.params?.companyId;

        if (company_id == undefined) {
            return;
        }

        return http.delete("companies/" + company_id + "/tasks/" + item.id + "/");
    }
}

class ContactCompany {
    static permissions = [
        {
            name: i18n.t('erstellen_gross'),
            perm: "user.add_contact",
        },
        {
            name: i18n.t('bearbeiten'),
            perm: "user.change_contact",
        },
        {
            name: i18n.t('loeschen'),
            perm: "user.delete_contact",
        },
    ]
    constructor() {
        this.id = null;
        this.forename = null; 
        this.surname = null; 
        this.phone = null;
        this.email = null
    }
    static async list(name=null, params=null) {
        return list("contacts/", name, params)
    }
    static save(contact) {
        const company_id = Router.currentRoute.params?.companyId;
    
        if (company_id == undefined) {
          return;
        }
    
        const data = { 
            ...contact,
        }
    
        let url = "companies/" + company_id + "/contacts/"
        if (data.id == undefined || data.id == null) {
            // Create
            return http.post(url, data);
        } else {
            // Update
            return http.patch(url + data.id + "/", data);
        }
    }
    static remove(item) {
        const company_id = Router.currentRoute.params?.companyId;
        if (company_id == undefined) {
            return;
        }
        return http.delete("companies/" + company_id + "/contacts/" + item.id + "/");
    }
}


class UserCompany {
    static permissions = [
        {
            name: i18n.t('erstellen_gross'),
            perm: "user.add_usercompany",
        },
        {
            name: i18n.t('bearbeiten'),
            perm: "user.change_usercompany",
        },
        {
            name: i18n.t('loeschen'),
            perm: "user.delete_usercompany",
        },
    ]

    static getPermissions() {
        return [
            {
                name: i18n.t('erstellen_gross'),
                perm: "user.add_usercompany",
            },
            {
                name: i18n.t('bearbeiten'),
                perm: "user.change_usercompany",
            },
            {
                name: i18n.t('loeschen'),
                perm: "user.delete_usercompany",
            },
        ]
    }

    constructor() {
        this.id = null;
        this.user = null;
        this.role = null;
    }

    static async list(name = null, params = {}) {
        return list("users/", name, params)
    }

    static retrieve(id) {
        const company_id = Router.currentRoute.params?.companyId;

        if (company_id == undefined) {
            return;
        }

        return http.get("companies/" + company_id + "/users/" + id + "/");
    }

    static save(user) {
        const company_id = Router.currentRoute.params?.companyId;

        if (company_id == undefined) {
            return;
        }

        const data = { ...user }

        let url = "companies/" + company_id + "/users/"

        if (user.id !== undefined) {
            data.role = data.role.id;
            return http.patch(url + user.id + "/", data);
        } else {
            return http.post(url, data);
        }
    }

    static get_permissions(company_id) {
        return http.get("companies/" + company_id + "/users/permissions/");
    }

    static favorite(company_id) {
        return http.patch("companies/" + company_id + "/users/favorite/");
    }

    static async remove(id) {
        const company_id = Router.currentRoute.params?.companyId;

        if (company_id == undefined) {
            return;
        }

        return http.delete("companies/" + company_id + "/users/" + id + "/");
    }
}

function has_perm(name = '') {
    const globalStore = useGlobalStore();
    return globalStore.get_perms.includes(name);
}

function get_avatar(user = null) {
    const globalStore = useGlobalStore();

    if (user == null) {
        user = globalStore.get_user;
    }

    if (user.forename == '' || user.surname == '') {
        return "";
    }

    if (user.forename != undefined && user.surname != undefined)
        return Array.from(user.forename)[0] + Array.from(user.surname)[0]

    return "";
}

function getPersonData(person) {
    if (person.type == 'user' && (person.forename == '' || person.surname == '')) {
        return person.email;
    } else {
        return person.forename + " " + person.surname;
    }
}

class UserSettings {
    static async list() {
        const response = http.get("/users/settings/");
        return response;
    }

    static save(data) {
        return http.patch("/users/settings/", data);
    }
}

export { User, UserSettings, UserCompany, UserTasks, ContactCompany ,has_perm, get_avatar, getPersonData };
