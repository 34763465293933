import { http } from "@/plugins/axios";
import Router from "@/router/index";
import { list } from "@/classes/helpers"
import i18n from '@/plugins/i18n';
import { useGlobalStore } from "@/store/globalStore";


class Resource {
    static permissions = [
        {
            name: i18n.t('vererben'),
            perm: "resource_pool.can_inherit",
        },
        {
            name: i18n.t('vererbung_rückgaengig'),
            perm: "resource_pool.revert_inheritance",
        },
    ]

    static getPermissions() {
        return [
            {
                name: i18n.t('vererben'),
                perm: "resource_pool.can_inherit",
            },
            {
                name: i18n.t('vererbung_rückgaengig'),
                perm: "resource_pool.revert_inheritance",
            },
        ]
    }
}

class MultilingualResourceLabelFilter {
    static label_key = 'resource_labels';

    /* list function that already filters purposes */
    static async list(name = null, params = {}, resource_without_label = true, disable_label_filter = false, all_languages=false) {
        const store = useGlobalStore();
        const resource_labels = store.get_resource_labels;

        if (!disable_label_filter) {
            if (Array.isArray(resource_labels) && resource_labels.length > 0 ) {
                // Setze resource_labels basierend auf den Labels im Store
                params['resource_labels'] = resource_labels.map(label => label.id);

                // Füge 'resource_without_label' hinzu, falls gewünscht
                if (resource_without_label) {
                    params['resource_labels'].push('resource_without_label');
                }
            } else {
                // Falls get_resource_labels kein Array ist, setze resource_labels als leeres Array
                params['resource_labels'] = resource_without_label ? ['resource_without_label'] : [];
            }
        }

        // Hier das `all_languages`-Flag hinzufügen
        if (all_languages) {
            params['all_languages'] = 'true';  // Damit es als Query-Parameter mitgesendet wird
        }

        return await list(this.base_url, name, params, true);
    }

    static save(item) {
        const transformedTranslations = Object.entries(item.names).map(([id, obj]) => ({
            language: id,  // Die ID der Sprache
            name: obj.name // Der Name der Übersetzung
        }));
        
        const resource_labels = item.resource_labels;
        const company_id = Router.currentRoute.params?.companyId;

        if (company_id == undefined) {
            return;
        }

        const payload = {
            resource_labels: resource_labels,
            translations: transformedTranslations,
        };

        if (item.risk_s !== undefined && item.risk_s !== null) {
            payload.risk_s = item.risk_s;
        }

        if (item.id !== undefined) {
            // Update
            return http.patch("companies/" + company_id + "/" + this.base_url + item.id + "/", payload);
        } else {
            // Create
            return http.post("companies/" + company_id + "/" + this.base_url, payload);
        } 
    }

    static retrieve(item_id) {
        const company_id = Router.currentRoute.params?.companyId;

        if (company_id == undefined) {
            return;
        }

        return http.get("companies/" + company_id + "/" + this.base_url + item_id + "/");
    }
}

/* Parent class that gives function to child classes to choose between all labels or only company specific labels */
class ResourceLabelFilter {
    static label_key = 'resource_labels';

    /* list function that already filters purposes */
    static async list(name = null, params = {}, resource_without_label = true, disable_label_filter = false, all_languages=false) {
        const store = useGlobalStore();

        const resource_labels = store.get_resource_labels;

        if (!disable_label_filter) {
            if (Array.isArray(resource_labels) && resource_labels.length > 0 ) {
                // Setze resource_labels basierend auf den Labels im Store
                params['resource_labels'] = resource_labels.map(label => label.id);

                // Füge 'resource_without_label' hinzu, falls gewünscht
                if (resource_without_label) {
                    params['resource_labels'].push('resource_without_label');
                }
            } else {
                // Falls get_resource_labels kein Array ist, setze resource_labels als leeres Array
                params['resource_labels'] = resource_without_label ? ['resource_without_label'] : [];
            }
        }

        // Hier das `all_languages`-Flag hinzufügen
        if (all_languages) {
            params['all_languages'] = 'true';  // Damit es als Query-Parameter mitgesendet wird
        }

        return await list(this.base_url, name, params, true);
    }
}

class Purpose extends MultilingualResourceLabelFilter {
    static base_url = "resource/purposes/";

    static permissions = [
        {
            name: i18n.t('erstellen_gross'),
            perm: "resource_pool.add_purpose",
        },
        {
            name: i18n.t('bearbeiten'),
            perm: "resource_pool.change_purpose",
        },
        {
            name: i18n.t('loeschen'),
            perm: "resource_pool.delete_purpose",
        },
    ]

    static getPermissions() {
        return [
            {
                name: i18n.t('erstellen_gross'),
                perm: "resource_pool.add_purpose",
            },
            {
                name: i18n.t('bearbeiten'),
                perm: "resource_pool.change_purpose",
            },
            {
                name: i18n.t('loeschen'),
                perm: "resource_pool.delete_purpose",
            },
        ]
    }

    constructor(
        data = {
            name: "",
        }
    ) {
        super();
        this.name = data.name;
    }


    static remove(item) {
        const company_id = Router.currentRoute.params?.companyId;

        if (company_id == undefined) {
            return;
        }

        return http.delete("companies/" + company_id + "/resource/purposes/" + item.id + "/");
    }
}

class LegalBasis extends MultilingualResourceLabelFilter {
    static base_url = "resource/legal-basis/";

    static permissions = [
        {
            name: i18n.t('erstellen_gross'),
            perm: "resource_pool.add_legalbasis",
        },
        {
            name: i18n.t('bearbeiten'),
            perm: "resource_pool.change_legalbasis",
        },
        {
            name: i18n.t('loeschen'),
            perm: "resource_pool.delete_legalbasis",
        },
    ]

    static getPermissions() {
        return [
            {
                name: i18n.t('erstellen_gross'),
                perm: "resource_pool.add_legalbasis",
            },
            {
                name: i18n.t('bearbeiten'),
                perm: "resource_pool.change_legalbasis",
            },
            {
                name: i18n.t('loeschen'),
                perm: "resource_pool.delete_legalbasis",
            }
        ]
    }

    constructor(
        data = {
            name: "",
        }
    ) {
        super();
        this.name = data.name
    }


    static remove(item) {
        const company_id = Router.currentRoute.params?.companyId;

        if (company_id == undefined) {
            return;
        }

        return http.delete("companies/" + company_id + "/resource/legal-basis/" + item.id + "/");
    }
}


class CategoryReceiver extends MultilingualResourceLabelFilter {

    static base_url = "resource/categories-receiver/";
    static permissions = [
        {
            name: i18n.t('erstellen_gross'),
            perm: "resource_pool.add_categoryreceiver",
        },
        {
            name: i18n.t('bearbeiten'),
            perm: "resource_pool.change_categoryreceiver",
        },
        {
            name: i18n.t('loeschen'),
            perm: "resource_pool.delete_categoryreceiver",
        },
    ]

    static getPermissions() {
        return [
            {
                name: i18n.t('erstellen_gross'),
                perm: "resource_pool.add_categoryreceiver",
            },
            {
                name: i18n.t('bearbeiten'),
                perm: "resource_pool.change_categoryreceiver",
            },
            {
                name: i18n.t('loeschen'),
                perm: "resource_pool.delete_categoryreceiver",
            },
        ]
    }

    constructor(
        data = {
            name: "",
        }
    ) {
        super();
        this.name = data.name
    }

    static remove(item) {
        const company_id = Router.currentRoute.params?.companyId;

        if (company_id == undefined) {
            return;
        }

        return http.delete("companies/" + company_id + "/resource/categories-receiver/" + item.id + "/");
    }
}


class PersonalData extends MultilingualResourceLabelFilter {

    static base_url = "resource/categories-personal-data/";

    static permissions = [
        {
            name: i18n.t('erstellen_gross'),
            perm: "resource_pool.add_categoriepersonaldata",
        },
        {
            name: i18n.t('bearbeiten'),
            perm: "resource_pool.change_categoriepersonaldata",
        },
        {
            name: i18n.t('loeschen'),
            perm: "resource_pool.delete_categoriepersonaldata",
        },
    ]

    static getPermissions() {
        return [
            {
                name: i18n.t('erstellen_gross'),
                perm: "resource_pool.add_categoriepersonaldata",
            },
            {
                name: i18n.t('bearbeiten'),
                perm: "resource_pool.change_categoriepersonaldata",
            },
            {
                name: i18n.t('loeschen'),
                perm: "resource_pool.delete_categoriepersonaldata",
            },
        ]
    }

    constructor(
        data = {
            name: "",
            risk_s: 0,
        }
    ) {
        super();
        this.name = data.name
        this.risk_s = data.risk_s
    }

    static remove(item) {
        const company_id = Router.currentRoute.params?.companyId;

        if (company_id == undefined) {
            return;
        }

        return http.delete("companies/" + company_id + "/resource/categories-personal-data/" + item.id + "/");
    }
}

class SpecialPersonalData extends MultilingualResourceLabelFilter{

    static base_url = "resource/special-categories-personal-data/";
    static permissions = [
        {
            name: i18n.t('erstellen_gross'),
            perm: "resource_pool.add_specialcategoriepersonaldata",
        },
        {
            name: i18n.t('bearbeiten'),
            perm: "resource_pool.change_specialcategoriepersonaldata",
        },
        {
            name: i18n.t('loeschen'),
            perm: "resource_pool.delete_specialcategoriepersonaldata",
        },
    ]

    static getPermissions() {
        return [
            {
                name: i18n.t('erstellen_gross'),
                perm: "resource_pool.add_specialcategoriepersonaldata",
            },
            {
                name: i18n.t('bearbeiten'),
                perm: "resource_pool.change_specialcategoriepersonaldata",
            },
            {
                name: i18n.t('loeschen'),
                perm: "resource_pool.delete_specialcategoriepersonaldata",
            },
        ]
    }

    constructor(
        data = {
            name: "",
            risk_s: 0,
        }
    ) {
        super();
        this.name = data.name
        this.risk_s = data.risk_s
    }

    static remove(item) {
        const company_id = Router.currentRoute.params?.companyId;

        if (company_id == undefined) {
            return;
        }

        return http.delete("companies/" + company_id + "/resource/special-categories-personal-data/" + item.id + "/");
    }
}

class DataSubject extends MultilingualResourceLabelFilter {

    static base_url = "resource/categories-data-subject/";
    static permissions = [
        {
            name: i18n.t('erstellen_gross'),
            perm: "resource_pool.add_categoriedatasubject",
        },
        {
            name: i18n.t('bearbeiten'),
            perm: "resource_pool.change_categoriedatasubject",
        },
        {
            name: i18n.t('loeschen'),
            perm: "resource_pool.delete_categoriedatasubject",
        },
    ]

    static getPermissions() {
        return [
            {
                name: i18n.t('erstellen_gross'),
                perm: "resource_pool.add_categoriedatasubject",
            },
            {
                name: i18n.t('bearbeiten'),
                perm: "resource_pool.change_categoriedatasubject",
            },
            {
                name: i18n.t('loeschen'),
                perm: "resource_pool.delete_categoriedatasubject",
            },
        ]
    }

    constructor(
        data = {
            name: "",
            risk_s: 0,
        }
    ) {
        super();
        this.name = data.name;
        this.risk_s = data.risk_s;
    }

    static remove(item) {
        const company_id = Router.currentRoute.params?.companyId;

        if (company_id == undefined) {
            return;
        }
        return http.delete("companies/" + company_id + "/resource/categories-data-subject/" + item.id + "/");
    }
}

class Measure extends MultilingualResourceLabelFilter {
    static base_url = "resource/measures/"
    static permissions = [
        {
            name: i18n.t('erstellen_gross'),
            perm: "resource_pool.add_technicalorganisationalmeasure",
        },
        {
            name: i18n.t('bearbeiten'),
            perm: "resource_pool.change_technicalorganisationalmeasure",
        },
        {
            name: i18n.t('loeschen'),
            perm: "resource_pool.delete_technicalorganisationalmeasure",
        },
    ]

    static getPermissions() {
        return [
            {
                name: i18n.t('erstellen_gross'),
                perm: "resource_pool.add_technicalorganisationalmeasure",
            },
            {
                name: i18n.t('bearbeiten'),
                perm: "resource_pool.change_technicalorganisationalmeasure",
            },
            {
                name: i18n.t('loeschen'),
                perm: "resource_pool.delete_technicalorganisationalmeasure",
            },
        ]
    }

    constructor(
        data = {
            name: "",
            category: null,
        }
    ) {
        super()
        this.name = data.name
        this.category = data.category
    }

    static async list(name = null, params = {}) {
        return list("resource/measures/", name, params)
    }

    static save(item) {
        const transformedTranslations = Object.entries(item.names).map(([id, obj]) => ({
            language: id,  // Die ID der Sprache
            name: obj.name // Der Name der Übersetzung
        }));
        
        const company_id = Router.currentRoute.params?.companyId;

        if (company_id == undefined) {
            return;
        }

        const payload = {
            category: item.category.id,
            translations: transformedTranslations,
        };


        if (item.id !== undefined) {
            // Update
            return http.patch("companies/" + company_id + "/" + this.base_url + item.id + "/", payload);
        } else {
            // Create
            return http.post("companies/" + company_id + "/" + this.base_url, payload);
        } 
    }

    static remove(item) {
        const company_id = Router.currentRoute.params?.companyId;

        if (company_id == undefined) {
            return;
        }

        return http.delete("companies/" + company_id + "/resource/measures/" + item.id + "/");
    }
}

class Repository extends MultilingualResourceLabelFilter {
    static base_url = "resource/repositories/";
    static permissions = [
        {
            name: i18n.t('erstellen_gross'),
            perm: "resource_pool.add_repository",
        },
        {
            name: i18n.t('bearbeiten'),
            perm: "resource_pool.change_repository",
        },
        {
            name: i18n.t('loeschen'),
            perm: "resource_pool.delete_repository",
        },
    ];

    static getPermissions() {
        return [
            {
                name: i18n.t('erstellen_gross'),
                perm: "resource_pool.add_repository",
            },
            {
                name: i18n.t('bearbeiten'),
                perm: "resource_pool.change_repository",
            },
            {
                name: i18n.t('loeschen'),
                perm: "resource_pool.delete_repository",
            },
        ];
    }

    constructor(
        data = {
            name: "",
            type: null,
        }
    ) {
        super()
        this.name = data.name
        this.type = data.type
    }

    static async list(name = null, params = {}) {
        return list("resource/repositories/", name, params)
    }

    static save(item) {
        const transformedTranslations = Object.entries(item.names).map(([id, obj]) => ({
            language: id,  // Die ID der Sprache
            name: obj.name // Der Name der Übersetzung
        }));
        
        const company_id = Router.currentRoute.params?.companyId;

        if (company_id == undefined) {
            return;
        }

        const payload = {
            type: item.category.id,
            translations: transformedTranslations,
        };


        if (item.id !== undefined) {
            // Update
            return http.patch("companies/" + company_id + "/" + this.base_url + item.id + "/", payload);
        } else {
            // Create
            return http.post("companies/" + company_id + "/" + this.base_url, payload);
        } 
    }

    static remove(item) {
        const company_id = Router.currentRoute.params?.companyId;

        if (company_id == undefined) {
            return;
        }

        return http.delete("companies/" + company_id + "/resource/repositories/" + item.id + "/");
    }
}

class DeletionPeriod {
    static permissions = [
        {
            name: i18n.t('erstellen_gross'),
            perm: "resource_pool.add_deletionperiod",
        },
        {
            name: i18n.t('bearbeiten'),
            perm: "resource_pool.change_deletionperiod",
        },
        {
            name: i18n.t('loeschen'),
            perm: "resource_pool.delete_deletionperiod",
        },
    ]

    static getPermissions() {
        return [
            {
                name: i18n.t('erstellen_gross'),
                perm: "resource_pool.add_deletionperiod",
            },
            {
                name: i18n.t('bearbeiten'),
                perm: "resource_pool.change_deletionperiod",
            },
            {
                name: i18n.t('loeschen'),
                perm: "resource_pool.delete_deletionperiod",
            },
        ]
    }

    constructor(
        data = {
            period: 1,
            entity: null,
        }
    ) {
        this.period = data.period
        this.entity = data.entity
    }

    // eslint-disable-next-line
    static async list(period = null, params = {}) {
        /*if (period != null) {
            params["period"] = period;
        }*/

        return list("resource/deletion-periods/", null, params)
    }

    static save(item) {
        const company_id = Router.currentRoute.params?.companyId;

        if (company_id == undefined) {
            return;
        }

        if (item.id !== undefined) {
            // Update
            return http.patch("companies/" + company_id + "/resource/deletion-periods/" + item.id + "/", item);
        } else {
            // Create
            return http.post("companies/" + company_id + "/resource/deletion-periods/", item);
        }
    }

    static remove(item) {
        const company_id = Router.currentRoute.params?.companyId;

        if (company_id == undefined) {
            return;
        }

        return http.delete("companies/" + company_id + "/resource/deletion-periods/" + item.id + "/");
    }
}

class Status {
    static permissions = [
        {
            name: i18n.t('erstellen_gross'),
            perm: "resource_pool.add_status",
        },
        {
            name: i18n.t('bearbeiten'),
            perm: "resource_pool.change_status",
        },
        {
            name: i18n.t('loeschen'),
            perm: "resource_pool.delete_status",
        },
    ]

    static getPermissions() {
        return [
            {
                name: i18n.t('erstellen_gross'),
                perm: "resource_pool.add_status",
            },
            {
                name: i18n.t('bearbeiten'),
                perm: "resource_pool.change_status",
            },
            {
                name: i18n.t('loeschen'),
                perm: "resource_pool.delete_status",
            },
        ]
    }

    constructor(
        data = {
            name: "",
            background_color: "",
            color: "",
        }
    ) {
        this.name = data.name
        this.background_color = data.background_color
        this.color = data.color
    }

    static async list(name = null, params = {}) {
        return list("resource/status/", name, params)
    }
}


class TaskCategorie {
    constructor(
        data = {
            name: "",
        }
    ) {
        this.name = data.name
    }

    static async list(name = null, params = {}) {
        return list("resource/task-categories/", name, params)
    }
}


class RiskSource extends MultilingualResourceLabelFilter{
    static base_url = "/resource/risk-sources";

    static permissions = [
        {
            name: i18n.t('erstellen_gross'),
            perm: "resource_pool.add_risksource",
        },
        {
            name: i18n.t('bearbeiten'),
            perm: "resource_pool.change_risksource",
        },
        {
            name: i18n.t('loeschen'),
            perm: "resource_pool.delete_risksource",
        },
    ]

    static getPermissions() {
        return [
            {
                name: i18n.t('erstellen_gross'),
                perm: "resource_pool.add_risksource",
            },
            {
                name: i18n.t('bearbeiten'),
                perm: "resource_pool.change_risksource",
            },
            {
                name: i18n.t('loeschen'),
                perm: "resource_pool.delete_risksource",
            },
        ]
    }

    constructor(
        data = {
            name: "",
        }
    ) {
        super()
        this.name = data.name
    }

    static async list(name = null, params = {}) {
        return list("resource/risk-sources/", name, params)
    }


    static remove(item) {
        const company_id = Router.currentRoute.params?.companyId;

        if (company_id == undefined) {
            return;
        }

        return http.delete("companies/" + company_id + "/resource/risk-sources/" + item.id + "/");
    }
}


class DocumentCategory {
    static permissions = [
        {
            name: i18n.t('erstellen_gross'),
            perm: "resource_pool.add_documentcategory",
        },
        {
            name: i18n.t('bearbeiten'),
            perm: "resource_pool.change_documentcategory",
        },
        {
            name: i18n.t('loeschen'),
            perm: "resource_pool.delete_documentcategory",
        },
    ]

    static getPermissions() {
        return [
            {
                name: i18n.t('erstellen_gross'),
                perm: "resource_pool.add_documentcategory",
            },
            {
                name: i18n.t('bearbeiten'),
                perm: "resource_pool.change_documentcategory",
            },
            {
                name: i18n.t('loeschen'),
                perm: "resource_pool.delete_documentcategory",
            },
        ]
    }

    constructor(
        data = {
            name: "",
            icon: "",
            color: "",
        }
    ) {
        this.name = data.name
        this.icon = data.icon
        this.color = data.color
    }

    static async list(name = null, params = {}) {
        return list("resource/document-categories/", name, params)
    }

    static save(item) {
        const company_id = Router.currentRoute.params?.companyId;

        if (company_id == undefined) {
            return;
        }

        if (item.id !== undefined) {
            // Update
            return http.patch("companies/" + company_id + "/resource/document-categories/" + item.id + "/", item);
        } else {
            // Create
            return http.post("companies/" + company_id + "/resource/document-categories/", item);
        }
    }

    static remove(item) {
        const company_id = Router.currentRoute.params?.companyId;

        if (company_id == undefined) {
            return;
        }

        return http.delete("companies/" + company_id + "/resource/document-categories/" + item.id + "/");
    }
}


class ResourceLabel {
    static permissions = [
        {
            name: i18n.t('erstellen_gross'),
            perm: "resource_pool.add_resourcelabel",
        },
        {
            name: i18n.t('bearbeiten'),
            perm: "resource_pool.change_resourcelabel",
        },
        {
            name: i18n.t('loeschen'),
            perm: "resource_pool.delete_resourcelabel",
        },
    ]

    static getPermissions() {
        return [
            {
                name: i18n.t('erstellen_gross'),
                perm: "resource_pool.add_resourcelabel",
            },
            {
                name: i18n.t('bearbeiten'),
                perm: "resource_pool.change_resourcelabel",
            },
            {
                name: i18n.t('loeschen'),
                perm: "resource_pool.delete_resourcelabel",
            },
        ]
    }

    constructor(
        data = {
            name: "",
            color: "#e0e0e0",
        }
    ) {
        this.name = data.name
        this.color = data.color
    }

    static async list(name = null, params = {}) {
        //params['lang'] = 'eng'
        return list("resource/resource-labels/", name, params)
    }

    static save(item) {
        const company_id = Router.currentRoute.params?.companyId;

        if (company_id == undefined) {
            return;
        }

        if (item.id !== undefined) {
            // Update
            return http.patch("companies/" + company_id + "/resource/resource-labels/" + item.id + "/", item);
        } else {
            // Create
            return http.post("companies/" + company_id + "/resource/resource-labels/", item);
        }
    }

    static remove(item) {
        const company_id = Router.currentRoute.params?.companyId;

        if (company_id == undefined) {
            return;
        }

        return http.delete("companies/" + company_id + "/resource/resource-labels/" + item.id + "/");
    }
}


class Label {
    static permissions = [
        {
            name: i18n.t('erstellen_gross'),
            perm: "resource_pool.add_label",
        },
        {
            name: i18n.t('bearbeiten'),
            perm: "resource_pool.change_label",
        },
        {
            name: i18n.t('loeschen'),
            perm: "resource_pool.delete_label",
        },
    ]

    static getPermissions() {
        return [
            {
                name: i18n.t('erstellen_gross'),
                perm: "resource_pool.add_label",
            },
            {
                name: i18n.t('bearbeiten'),
                perm: "resource_pool.change_label",
            },
            {
                name: i18n.t('loeschen'),
                perm: "resource_pool.delete_label",
            },
        ]
    }

    constructor(
        data = {
            name: "",
            color: "#e0e0e0",
        }
    ) {
        this.name = data.name
        this.color = data.color
    }

    static async list(name = null, params = {}) {
        //params['lang'] = 'eng'

        return list("resource/labels/", name, params)
    }

    static save(item) {
        const company_id = Router.currentRoute.params?.companyId;

        if (company_id == undefined) {
            return;
        }

        if (item.id !== undefined) {
            // Update
            return http.patch("companies/" + company_id + "/resource/labels/" + item.id + "/", item);
        } else {
            // Create
            return http.post("companies/" + company_id + "/resource/labels/", item);
        }
    }

    static remove(item) {
        const company_id = Router.currentRoute.params?.companyId;

        if (company_id == undefined) {
            return;
        }

        return http.delete("companies/" + company_id + "/resource/labels/" + item.id + "/");
    }
}

class ExternalCommunicationType extends MultilingualResourceLabelFilter {
    static base_url = "resource/external-communication-types/"
    static permissions = [
        {
            name: i18n.t('erstellen_gross'),
            perm: "resource_pool.add_externalcommunicationtype",
        },
        {
            name: i18n.t('bearbeiten'),
            perm: "resource_pool.change_externalcommunicationtype",
        },
    ]

    static getPermissions() {
        return [
            {
                name: i18n.t('erstellen_gross'),
                perm: "resource_pool.add_externalcommunicationtype",
            },
            {
                name: i18n.t('bearbeiten'),
                perm: "resource_pool.change_externalcommunicationtype",
            },
        ]
    }

    constructor(
        data = {
            name: "",
        }
    ) {
        super(),
        this.name = data.name
    }

    static async list(name = null, params = {}) {
        //params['lang'] = 'eng'

        return list("resource/external-communication-types/", name, params)
    }

}


class SupervisoryAuthority {
    static permissions = [
        {
            name: i18n.t('erstellen_gross'),
            perm: "resource_pool.add_supervisoryauthority",
        },
        {
            name: i18n.t('bearbeiten'),
            perm: "resource_pool.change_supervisoryauthority",
        },
        {
            name: i18n.t('loeschen'),
            perm: "resource_pool.delete_supervisoryauthority",
        },
    ]

    static getPermissions() {
        return [
            {
                name: i18n.t('erstellen_gross'),
                perm: "resource_pool.add_supervisoryauthority",
            },
            {
                name: i18n.t('bearbeiten'),
                perm: "resource_pool.change_supervisoryauthority",
            },
            {
                name: i18n.t('loeschen'),
                perm: "resource_pool.delete_supervisoryauthority",
            },
        ]
    }

    constructor(
        data = {
            name: "",
            forename: "",
            surname: "",
            street: "",
            house_number: "",
            phone: "",
            email: "",
            location: null,
        }
    ) {
        this.name = data.name
        this.forename = data.forename
        this.surname = data.surname
        this.street = data.street
        this.house_number = data.house_number
        this.phone = data.phone
        this.email = data.email
        this.location = data.location
    }

    static async list(name = null, params = {}) {
        return list("resource/supervisory-authorities/", name, params)
    }

    static save(item) {
        const company_id = Router.currentRoute.params?.companyId;

        if (company_id == undefined) {
            return;
        }

        // Flatten data
        const data = { ...item };

        data.location = data.location ? data.location.id : null;

        if (data.id !== undefined) {
            // Update
            return http.patch("companies/" + company_id + "/resource/supervisory-authorities/" + data.id + "/", data);
        } else {
            // Create
            return http.post("companies/" + company_id + "/resource/supervisory-authorities/", data);
        }
    }

    static remove(item) {
        const company_id = Router.currentRoute.params?.companyId;

        if (company_id == undefined) {
            return;
        }

        return http.delete("companies/" + company_id + "/resource/supervisory-authorities/" + item.id + "/");
    }
}


class DataBreachTyp extends MultilingualResourceLabelFilter {
    static base_url = "resource/data-breach-types/"
    static permissions = [
        {
            name: i18n.t('erstellen_gross'),
            perm: "resource_pool.add_databreachtype",
        },
        {
            name: i18n.t('bearbeiten'),
            perm: "resource_pool.change_databreachtype",
        },
        {
            name: i18n.t('loeschen'),
            perm: "resource_pool.delete_databreachtype",
        },
    ]

    static getPermissions() {
        return [
            {
                name: i18n.t('erstellen_gross'),
                perm: "resource_pool.add_databreachtype",
            },
            {
                name: i18n.t('bearbeiten'),
                perm: "resource_pool.change_databreachtype",
            },
            {
                name: i18n.t('loeschen'),
                perm: "resource_pool.delete_databreachtype",
            },
        ]
    }

    constructor(
        data = {
            name: "",
        }
    ) {
        super()
        this.name = data.name
    }



    static remove(item) {
        const company_id = Router.currentRoute.params?.companyId;

        if (company_id == undefined) {
            return;
        }

        return http.delete("companies/" + company_id + "/resource/data-breach-types/" + item.id + "/");
    }
}


class DataBreachReason extends MultilingualResourceLabelFilter {
    static base_url = "resource/data-breach-reasons/";
    static permissions = [
        {
            name: i18n.t('erstellen_gross'),
            perm: "resource_pool.add_databreachreason",
        },
        {
            name: i18n.t('bearbeiten'),
            perm: "resource_pool.change_databreachreason",
        },
        {
            name: i18n.t('loeschen'),
            perm: "resource_pool.delete_databreachreason",
        },
    ]

    static getPermissions() {
        return [
            {
                name: i18n.t('erstellen_gross'),
                perm: "resource_pool.add_databreachreason",
            },
            {
                name: i18n.t('bearbeiten'),
                perm: "resource_pool.change_databreachreason",
            },
            {
                name: i18n.t('loeschen'),
                perm: "resource_pool.delete_databreachreason",
            },
        ]
    }

    constructor(
        data = {
            name: "",
        }
    ) {
        super()
        this.name = data.name
    }

    static remove(item) {
        const company_id = Router.currentRoute.params?.companyId;

        if (company_id == undefined) {
            return;
        }

        return http.delete("companies/" + company_id + "/resource/data-breach-reasons/" + item.id + "/");
    }
}


class DataBreachRisk {
    constructor(
        data = {
            name: "",
        }
    ) {
        this.name = data.name
    }

    static async list(name = null, params = {}) {
        //params['lang'] = 'eng'

        return list("resource/data-breach-risks/", name, params)
    }
}

class DefaultLanguage {
    static async list() {
        const response = http.get("languages/")
        console.log(response);

        return response
    }
}

class Language {
    static async list(company_id) {
        if (company_id) {
            return http.get("companies/" + company_id + "/resource/company-languages/")
        } else {
            return list("resource/company-languages/")
        }
        
    }

    static remove(item) {
        const company_id = Router.currentRoute.params?.companyId;

        if (company_id == undefined) {
            return;
        }

        return http.delete("companies/" + company_id + "/resource/company-languages/" + item.id + "/");
    }

    static save(item) {
        const company_id = Router.currentRoute.params?.companyId;

        if (company_id == undefined || item == undefined) {
            return;
        }
        
        const payload = { languages: item.map(lang => lang.language_data.code) };
        // Create
        return http.post("companies/" + company_id + "/resource/company-languages/", payload);
    }
}

export { DefaultLanguage, Language, Purpose, LegalBasis, Resource, PersonalData, SupervisoryAuthority, DocumentCategory, DataBreachTyp, DataBreachRisk, DataBreachReason, ExternalCommunicationType, SpecialPersonalData, DataSubject, Measure, Repository, DeletionPeriod, Status, RiskSource, TaskCategorie, Label, CategoryReceiver, ResourceLabel, ResourceLabelFilter };
