import { http } from "@/plugins/axios";
import Router from "@/router/index";
import { list } from "@/classes/helpers"
import { File } from "@/classes/File";
import i18n from '@/plugins/i18n'

class Task extends File {
  static url = "/tasks/"

  static permissions = [
    {
      name: i18n.t('erstellen_gross'),
      perm: "task.add_task",
    },
    {
      name: i18n.t('bearbeiten'),
      perm: "task.change_task",
    },
    {
      name: i18n.t('loeschen'),
      perm: "task.delete_task",
    },
  ]

  static getPermissions() {
    return [
      {
        name: i18n.t('erstellen_gross'),
        perm: "task.add_task",
      },
      {
        name: i18n.t('bearbeiten'),
        perm: "task.change_task",
      },
      {
        name: i18n.t('loeschen'),
        perm: "task.delete_task",
      },
    ]
  }

  constructor(
    data = {
      name: "",
      description: "",
      comments: [],
    }
  ) {
    super();
    this.name = data.name;
    this.description = data.description;
    this.comments = data.comments;
    this.persons = [];
    this.departments = [];
    this.date = null;
    this.author = null;
    this.deadline = null;
    this.status = null;
    this.priority = null;
    this.periodic = false;
    this.end_date = null;
    this.period = {
      years: 0,
      months: 0,
      weeks: 0,
      days: 0,
    }
    this.labels = [];
  }

  static retrieve(id) {
    const company_id = Router.currentRoute.params?.companyId;

    if (company_id == undefined) {
      return;
    }

    return http.get("companies/" + company_id + "/tasks/" + id + "/");
  }

  static async list(name = null, params = {}) {
    return list("tasks/", name, params)
  }

  static save(task) {
    const company_id = Router.currentRoute.params?.companyId;

    if (company_id == undefined) {
      return;
    }

    const data = { ...task }

    // Flatten data
    let persons = []
    let departments = []

    // Check if keys in task
    if (Object.hasOwn(data, 'persons')) {
      data.persons.forEach((item) => {
        persons.push(item.id)
      })

      data.persons = persons;
    }

    if (Object.hasOwn(data, 'departments')) {
      data.departments.forEach((item) => {
        departments.push(item.id)
      })

      data.departments = departments;
    }

    if (Object.hasOwn(data, 'status')) {
      data.status = data.status != null ? data.status.id : null;
    }

    if (Object.hasOwn(data, 'labels')) {
      data.labels = data.labels.map(item => item.id);
    }

    if (data.id !== undefined) {
      // Update
      return http.patch("companies/" + company_id + "/tasks/" + data.id + "/", data);
    } else {
      // Create
      return http.post("companies/" + company_id + "/tasks/", data);
    }
  }

  static remove(item) {
    const company_id = Router.currentRoute.params?.companyId;

    if (company_id == undefined) {
      return;
    }

    return http.delete("companies/" + company_id + "/tasks/" + item.id + "/");
  }
}

export { Task }