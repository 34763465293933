import Router from "@/router/index";
import { http, baseURL } from "@/plugins/axios";

class CompanySettings {
  constructor(
    data = {
      theme: {
        primary: "#37b6ae",
        secondary: "#424242",
        accent: "#82B1FF",
        error: "#FF5252",
        info: "#2196F3",
        success: "#4CAF50",
        warning: "#FFC107",
        appbar_background: "#ffffff",
        appbar_help_icon: "#757575",
        appbar_nav_icon: "#757575",
        appbar_search_background: "#F0F0F0",
        background: "#f7f7f7",
        forms: { filled: true },
      },
    }
  ) {
    this.theme = data.theme;
  }

  static retrieve(company_id) {
    return http.get("companies/" + company_id + "/settings/")
  }

  static async save(settings) {
    console.log(settings);
    const company_id = Router.currentRoute.params?.companyId;

    if (company_id == undefined) {
      return;
    }

    const data = { ...settings }

    // Flatten
    if ('ica_officer' in data && data.ica_officer != null) {
      data.ica_officer = data.ica_officer.id;
    }

    // Extract only the IDs from resource_labels
    if (data.resource_labels && Array.isArray(data.resource_labels)) {
      data.resource_labels = data.resource_labels.map(label => label.id);
    }


    try {
      let response = await http.patch("companies/" + company_id + "/settings/", data);

      return response;
    } catch (error) {
      console.log(error);
    } 

  }

  static async upload(files) {
    const company_id = Router.currentRoute.params?.companyId;

    if (company_id == undefined) {
      return;
    }

    let formData = new FormData();

    for (var i = 0; i < files.length; i++) {
      formData.append('file', files[i].file);
    }

    return http.patch("companies/" + company_id + "/settings/", formData, {
      headers: {
        "Content-Type": "multipart/form-data"
      },
    });
  }
}

function downloadLogo(url) {
  return new Promise((resolve, reject) => {
    http.get(baseURL + url, { responseType: 'arraybuffer' }).then((res) => {
      const base64 = btoa(String.fromCharCode(...new Uint8Array(res.data)));
      resolve(`data:${res.headers["content-type"]};base64,${base64}`);
    }).catch(() => {
      reject();
    })
  });
}

export { CompanySettings, downloadLogo };
